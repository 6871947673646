'use client'

import { useSetAtom } from 'jotai'
import Cookies from 'js-cookie'
import { useEffect, useRef } from 'react'
import { SocialLoginProvider } from '../../__generated__/api-types'
import { graphqlRequesterOptions } from '../../config/graphql-requester-options'
import { emailPromptShownAtom } from '../email-capture/email-capture.store'
import { useRequestData } from '../request-data/pages-router/use-request-data'
import { ZID_KEY } from '../user/user.const'
import { useIsLoggedIn, useZutronId } from '../user/user.store'
import { createOneTapSignInFetcher } from './__generated__/one-tap-sign-in.gql'
import styles from './one-tap-sign-in.module.css'
import { OneTap } from './one-tap.service'
import { yieldOrContinue } from 'main-thread-scheduling'

const POPUP_DELAY = 1000
const POPUP_TIMEOUT = 30000
export const ONETAP_SHOWN_KEY = 'onetap_shown'
const MS_PER_HOUR = 1000 * 60 * 60

// This timeout should be fairly low. Whenever we check that we are in a
// "session", we extend the expiration by this timeout amount. The idea is if
// there is user activity within this window, then we consider that the same
// browsing session. Otherwise, it's considered a new session and we can begin
// showing one tap again.
export const SHOWN_EXPIRE_MS = MS_PER_HOUR * 3

type OneTapSignInProps = {
  tagSection: string
  displayedTag: string
  closedTag: string
  loggedInTag: string
}

function doTagging(event: string, section: string, item: string) {
  return async function () {
    await yieldOrContinue('interactive')
    window?.eventTracker?.track(event, { item, section })
  }
}

export function setOneTapShown() {
  try {
    const expireTime = new Date().getTime() + SHOWN_EXPIRE_MS
    localStorage.setItem(ONETAP_SHOWN_KEY, String(expireTime))
  } catch {}
}

export function isOneTapShown() {
  try {
    const value = localStorage.getItem(ONETAP_SHOWN_KEY)

    if (!value) return false

    const expireTime = Number(value)
    const currentTime = new Date().getTime()

    if (isNaN(expireTime) || currentTime > expireTime) {
      localStorage.removeItem(ONETAP_SHOWN_KEY)
      return false
    }

    // There is an expiration time and it's still in the future.
    // Extend the expiry time to extend the current "session"
    setOneTapShown()
    return true
  } catch {}

  return false
}

const oneTapSignInMutation = createOneTapSignInFetcher(graphqlRequesterOptions)

export function OneTapSignIn({
  tagSection,
  displayedTag,
  closedTag,
  loggedInTag,
}: OneTapSignInProps): JSX.Element {
  const zutronId = useZutronId()
  const setEmailPromptShown = useSetAtom(emailPromptShownAtom)
  const { off } = useRequestData()
  const bootTimeout = useRef<NodeJS.Timeout>()
  const displayTimeout = useRef<NodeJS.Timeout>()
  const userIsLoggedIn = useIsLoggedIn()

  const isDisabled = off.onetap

  useEffect(() => {
    function initOneTap() {
      bootTimeout.current && clearTimeout(bootTimeout.current)
      bootTimeout.current = setTimeout(() => {
        if (isOneTapShown()) {
          displayTimeout.current && clearTimeout(displayTimeout.current)
          return
        }

        const oneTap = OneTap.getInstance()

        oneTap
          .onDisplayed(async function onDisplayed() {
            await yieldOrContinue('idle')
            setOneTapShown()

            await yieldOrContinue('idle')
            displayTimeout.current && clearTimeout(displayTimeout.current)
            displayTimeout.current = setTimeout(
              () => oneTap.cancel(),
              POPUP_TIMEOUT
            )

            await yieldOrContinue('idle')
            void doTagging('impression', tagSection, displayedTag)()
          })
          .onCanceled(doTagging('click', tagSection, closedTag))
          .onAuthenticated(doTagging('log_in', tagSection, loggedInTag))
          .onTokenReceived(({ credential }) => {
            oneTapSignInMutation({
              input: {
                provider: SocialLoginProvider.Google,
                token: credential,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                zutronId: zutronId!,
              },
            }).tapOk((userLoginResponse) => {
              Cookies.set(ZID_KEY, userLoginResponse.userLoginSocial.zutronId, {
                path: '/',
              })

              setEmailPromptShown(true)

              // force client side refresh
              window.location.reload()
            })
          })
          .boot()
      }, POPUP_DELAY)
    }

    /**
     * NOTE: do not initiate one tap unless we have a zid first!
     * Also, do not initialze if one tap is off
     */
    if (zutronId && !isDisabled && !userIsLoggedIn) {
      initOneTap()

      return () => {
        bootTimeout.current && clearTimeout(bootTimeout.current)
        displayTimeout.current && clearTimeout(displayTimeout.current)
      }
    }
  }, [
    tagSection,
    displayedTag,
    closedTag,
    loggedInTag,
    isDisabled,
    userIsLoggedIn,
    setEmailPromptShown,
    zutronId,
  ])

  return <div className={styles.oneTapSignIn} id="one-tap-container" />
}
